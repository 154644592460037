@import './_all_vars.sass'

@font-face
  font-family: FiraGo
  src: url(../assets/fonts/FiraGo.woff)

// ------ Loging page buttons START -------

.login
  button.is-primary
    &.is-white
      color: black
      border-color: white
      background-color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #555
        background-color: #555 !important

  button.is-secondary
    &.is-white
      color: white
      border-color: white
      background-color: transparent

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: white
        border-color: #999
        background-color: #999 !important

  button.is-text
    &.is-white
      color: white

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading)
        color: #999 !important
        border-color: transparent
