$primary--text--colour: #000;
$primary--text--colour--light: $aurecon--grey--800;
$primary--text--colour--lighter: $aurecon--grey--700;
$secondary--text--colour: #fff;
$secondary--text--colour--light: $aurecon--grey--200;
$secondary--text--colour--dark: $aurecon--grey--400;

$breakpoint--mobile: 576px;
$breakpoint--tablet: 768px;
$breakpoint--desktop: 992px;
$breakpoint--desktop-large: 1200px;
