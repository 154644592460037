@import './_all_vars.sass'

.layoutContainer
  display: flex
  flex: 1
  flex-direction: column
  align-items: center
  justify-content: center

  div
    flex-direction: column
