.errorWrapper
  width: 400px
  height: calc(100vh - 100px)
  display: flex
  margin: 0 auto
  justify-content: center
  flex-direction: column

  .innerWrapper
    flex: 0
    text-align: center

    img
      width: 200px