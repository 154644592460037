@import './_all_vars.sass'
  
.modalDivider
  position: relative
  border-bottom: 1px solid gray
  margin: 16px -24px 24px

.staticContent
  display: flex
  flex: 1
  flex-direction: column
  overflow: auto
  margin: 16px
  padding: 16px

  h1
    font-family: FiraGO
    font-size: 30px
    font-weight: 400
    color: $primary--text--colour
    text-align: center
    
    sup
      font-size:6px
  p
    font-size: 14px
    text-align: center

    &.note
      color: $primary--text--colour--light
      font-style: italic

  a
    font-size: 14px

  .backToTopBtn
    text-align: right

  .list
    font-size: 16px

    > li
      font-weight: bold
      margin-top: 3em
  ul
    margin-bottom: 2em

    li
      line-height: 1.5em
      margin-bottom: 1em

  .tableList
    li
      font-family: Arial, Helvetica, sans-serif
      font-size: 14px

.center
  justify-content: center